import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual, omit } from 'lodash-es';

import paperOSLogo from '../../assets/images/paperOS_wordmark_yellow.png';
import googleIcon from '../../assets/images/google_icon.svg';
import Button from '../../components/common/Button';
import { InputPassword, InputStyledTextField } from '../../components/inputs';
import SavviLoading from '../../components/SavviLoading';
import { clearErrors, setUserFromToken } from '../../redux/modules/User/actions';
import {
  loginUser,
  requestVerificationEmail,
  verifyEmail,
} from '../../redux/modules/User/operations';
import { AuthErrors, isFetchingUser } from '../../redux/modules/User/selectors';
import { validateEmail, validatePassword } from '../../utils/FeatureTypes';

import './Login.scss';
import { decodeToken } from '../../redux/modules/User/utils';
import { useHistory, useLocation } from 'react-router';
import { setNotice } from '../../redux/modules/UI/actions';
import { BrandMeta } from '../../redux/modules/UI/selectors';

const Login = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const location = useLocation();
  const { hash, search, state: { redirectPathname, passedEmail } = {} } = location;

  const authErrors = useSelector(AuthErrors);
  const isFetching = useSelector(isFetchingUser);
  const brandMeta = useSelector(BrandMeta);
  const { support_email, display_name, assets = {} } = brandMeta || {};

  const [email, setEmail] = useState(passedEmail || '');
  const [prevAuthErrors, setPrevAuthErrors] = useState(authErrors);
  const [errors, setErrors] = useState(authErrors);
  const [isEmailVerified, setEmailVerified] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isPasswordAllowed, setIsPasswordAllowed] = useState(true);
  const [isPasswordPlainText, setPasswordPlainText] = useState(false);
  const [password, setPassword] = useState('');
  const idTokenInjector = useRef();

  const isWhiteLabel = window.location.href.includes('paperos');

  const emailRef = useCallback(
    node => {
      if (node !== null && !isEmailVerified) {
        node.focus();
      }
    },
    [isEmailVerified],
  );

  const passwordRef = useCallback(
    node => {
      if (node !== null && isEmailVerified) {
        node.focus();
      }
    },
    [isEmailVerified],
  );

  useEffect(() => {
    dispatch(clearErrors());
  }, [dispatch]);

  useEffect(() => {
    if (passedEmail) {
      dispatch(verifyEmail(passedEmail)).then(
        payload => {
          setEmailVerified(true);
          if (!payload.password_allowed) {
            setIsPasswordAllowed(false);
          }
          setErrors({});
        },
        error => {
          if (error.status === 404) {
            push({
              pathname: '/register',
              search,
              state: { email: passedEmail, redirectPathname },
            });
          }
        },
      );
    }
  }, [dispatch, passedEmail, push, redirectPathname, search]);

  useEffect(() => {
    if (!isEqual(authErrors, prevAuthErrors)) {
      setErrors({ ...errors, ...authErrors });
      setPrevAuthErrors(authErrors);
    }
  }, [authErrors, errors, prevAuthErrors]);

  const getCreds = () => ({
    email,
    password,
  });

  const injectGoogleAuth = () => {
    const decodedToken = decodeToken(idTokenInjector.current.value);
    dispatch(setUserFromToken(decodedToken));
  };

  const handleSubmit = (ev, updatedErrors) => {
    ev.preventDefault();
    let finalErrs = updatedErrors || errors;
    if (!isEmailVerified && !finalErrs.email) {
      ev.stopPropagation();
      setLoading(true);
      dispatch(verifyEmail(email)).then(
        payload => {
          if (!payload.password_allowed) {
            setIsPasswordAllowed(false);
          }
          setEmailVerified(true);
          setErrors({});
        },
        error => {
          if (error.status === 404) {
            push({ pathname: '/register', search, state: { email, redirectPathname } });
          }
        },
      );
      return setTimeout(() => setLoading(false), 500);
    } else if (isEmailVerified) {
      if (!isPasswordAllowed) {
        return dispatch(
          requestVerificationEmail({
            identifier: { type: 'email', value: email },
            template: 'magic-link',
            state: { email, redirectLocation: redirectPathname || search },
          }),
        ).then(e =>
          push({
            pathname: '/verify-code/magic-link',
            state: {
              email,
              redirectLocation: redirectPathname ||
                search || {
                  pathname: '/c/0/company-select',
                  state: { isInitialLoad: true },
                },
            },
          }),
        );
      } else if (!finalErrs.email && password && !finalErrs.password) {
        dispatch(clearErrors());
        setErrors({});
        return dispatch(loginUser(getCreds())).then(
          e => {
            setIsSubmitted(true);
          },
          error => {
            setIsSubmitted(true);
          },
        );
      }
    } else if (!isEmailVerified && finalErrs.email) {
      const doc = document.getElementById('email');
      if (doc.value) {
        setEmail(doc.value);
        setErrors({ ...errors, email: '' });
        return handleSubmit(ev, { ...errors, email: '' });
      }
    }
  };

  const validateLoginPassword = e => {
    const { name, value } = e.target;
    const password = validatePassword(
      value,
      6,
      'Password must be at least 6 characters long',
    );
    setErrors(!password ? omit(errors, name) : { ...errors, password });
  };

  const validateLoginEmail = e => {
    const { name, value } = e.target;
    const email = validateEmail(value);
    setErrors(!email ? omit(errors, name) : { ...errors, email });
  };

  const primaryActionLabel =
    (isEmailVerified && !isPasswordAllowed && 'Login By Email Link') ||
    (isEmailVerified && 'Login') ||
    'Next';
  const loginDescription =
    (isPasswordAllowed && 'Please enter your password below.') ||
    `You have not yet set a password for your ${display_name || ''} account.`;

  const wordmark = assets?.logo_with_paperos || paperOSLogo;
  const logoClasses = `
    login__logo ${
      (wordmark.includes('savvi') && 'login__logo--savvi') ||
      (wordmark.includes('fund_launch') && 'login__logo--fundLaunch') ||
      ''
    }`;

  return (
    <div className="login__container">
      <div className="login__logoWrapper">
        <img className={logoClasses} src={wordmark} alt="wordmark" />
      </div>
      <form className="login__form">
        {(isLoading || isFetching) && (
          <div className="login__loading-overlay">
            <SavviLoading size="md" />
          </div>
        )}
        <h2 className="login__heading">
          Welcome
          {isEmailVerified && (
            <>
              {','}
              <div
                className="login__emailLink"
                onClick={() => {
                  setErrors({});
                  setEmailVerified(false);
                  setIsPasswordAllowed(true);
                }}
              >
                <b>{email}</b>
                <span className="login__link">
                  <FontAwesomeIcon icon={['fal', 'edit']} />
                </span>
              </div>
              <small className="login__sub-heading">
                {!isPasswordAllowed && <FontAwesomeIcon icon="exclamation-circle" />}
                {loginDescription}
              </small>
            </>
          )}
          {!isEmailVerified && (
            <>
              {'!'}
              <small className="login__sub-heading">Please enter your email below.</small>
            </>
          )}
        </h2>
        <InputStyledTextField
          autoComplete="username"
          error={errors.email}
          inputClass="-js-login__input-email"
          isDataHidden={isEmailVerified}
          label={'Email'}
          name={'email'}
          onBlur={validateLoginEmail}
          onChange={e => {
            setEmail(e.target.value);
          }}
          ref={emailRef}
          type="email"
          value={email}
        />
        <InputPassword
          autoComplete="current-password"
          error={errors.password}
          hasToggle={true}
          inputClass="-js-login__input-password"
          isDataHidden={!isEmailVerified || !isPasswordAllowed}
          isDisabled={!isPasswordAllowed}
          isPlainText={isPasswordPlainText}
          label={'Password'}
          name={'password'}
          onBlur={ev => {
            if (!(ev.relatedTarget?.className || '').includes('send-email-link')) {
              validateLoginPassword(ev);
            }
          }}
          onChange={e => {
            setPassword(e.target.value);
          }}
          onToggle={() => setPasswordPlainText(!isPasswordPlainText)}
          ref={passwordRef}
          value={password}
        />
        {isSubmitted && errors.form && <div className="login__error">{errors.form}</div>}
        <div className="login__action">
          <Button
            className="-js-login__btn-main"
            isDisabled={isFetching}
            isFetching={isFetching}
            onClick={handleSubmit}
            type="submit"
          >
            {primaryActionLabel}
          </Button>
        </div>
        {isEmailVerified && (
          <div
            className={`login__linksWrapper ${
              passedEmail ? 'login__linksWrapper--center' : ''
            }`}
          >
            {!passedEmail && (
              <h4>
                {!isPasswordAllowed ? 'Want to use a password?' : 'Forgot password?'}
              </h4>
            )}
            {!isPasswordAllowed && (
              <Button
                buttonType="link"
                className="-js-login__btn-send-email-link"
                onClick={ev =>
                  dispatch(
                    requestVerificationEmail({
                      identifier: {
                        type: 'email',
                        value: email,
                      },
                      template: 'reset-password',
                      state: { email, redirectLocation: redirectPathname || search },
                    }),
                  ).then(e => {
                    dispatch(setNotice('Set new password email sent.'));
                  })
                }
                size="sm"
              >
                Send New Password Link
              </Button>
            )}
            {isPasswordAllowed && (
              <>
                <Button
                  buttonType="link"
                  className="-js-login__btn-send-email-link"
                  onClick={() =>
                    dispatch(
                      requestVerificationEmail({
                        identifier: { type: 'email', value: email },
                        template: 'magic-link',
                        state: { email, redirectLocation: redirectPathname || search },
                      }),
                    ).then(e =>
                      push({
                        pathname: '/verify-code/magic-link',
                        state: {
                          email,
                          redirectLocation:
                            redirectPathname || search || '/c/0/company-select',
                        },
                      }),
                    )
                  }
                >
                  Login By Email Link
                </Button>
                {' | '}
                <Button
                  buttonType="link"
                  className="-js-login__btn-forgot-password"
                  to={{ pathname: '/forgot-password', state: { email } }}
                  size="sm"
                >
                  Reset Password
                </Button>
              </>
            )}
          </div>
        )}
      </form>
      {!isWhiteLabel && (
        <div className="login__action">
          <Button
            className="-js-login__btn-gauth"
            buttonType="secondary"
            isDisabled={isFetching}
            isFetching={isFetching}
            href={'/api/authn/oidc/accounts.google.com/auth'}
            onClick={e => {
              let redirectLoc = redirectPathname;
              if (search) {
                redirectLoc = search;
              }
              if (!!redirectLoc) {
                localStorage.setItem('redirectLoc', redirectLoc);
              }
              if (hash && redirectLoc) {
                let redirectHash = hash;
                if (redirectLoc.includes('%2F')) {
                  redirectHash = hash.replace('#', '%23');
                }
                localStorage.setItem('redirectHash', redirectHash);
              }
              return e;
            }}
          >
            <img src={googleIcon} alt="google-logo" />
            Login with Google
          </Button>
          {window.location.href.match(/localhost/) && (
            <div>
              <input ref={idTokenInjector} id="googleTokenInjector" />
              <button onClick={injectGoogleAuth} className="google-token-injector">
                Inject Google Auth Token
              </button>
            </div>
          )}
        </div>
      )}
      <div className="login__text">
        <p>
          By continuing, you're confirming that you've read our
          <Button
            buttonType="link"
            to={{
              pathname: '/terms',
              state: { background: location },
            }}
            size="sm"
          >
            Terms &amp; Conditions
          </Button>
        </p>
        <p>
          For help with any issues please contact{' '}
          <Button
            buttonType="link"
            size="sm"
            href={`mailto:${support_email || 'support@savvi.legal'}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            support
          </Button>
        </p>
      </div>
    </div>
  );
};

export default Login;
